import type { components, paths } from '@/types';

import type {
  ConnectToSlotSwapPayload,
  NewSlotSwapPayload,
  SlotSwapInterestDTO,
} from '../pages/hub/types';
import ApiBase from './ApiBase';

export default class SparkHubService extends ApiBase {
  // slot-swaps
  async loadPosts() {
    // TODO: uncomment this when BE fixed the response type
    // type operation = paths['/v1.0/hub/slot-swaps']['get'];
    // type successResponse =
    //   operation['responses'][200]['content']['application/json'];

    // override
    type Response = Omit<
      components['schemas']['ListResponse_SlotSwapInterestDTO_'],
      'data'
    > & {
      data: SlotSwapInterestDTO[];
    };
    const response = await this.dedupeGet<Response>(`/v1.0/hub/slot-swaps`);
    return response.data.data;
  }

  async loadHubsData(projection = 'usgc') {
    type operation = paths['/v1.0/hub/usgc']['get'];
    type successResponse =
      operation['responses'][200]['content']['application/json'];
    const response = await this.dedupeGet<successResponse>(
      `/v1.0/hub/${projection}`,
    );
    return response.data.data;
  }

  async createSlotSwapPost(data: NewSlotSwapPayload) {
    const path = '/v1.0/hub/slot-swaps' as keyof paths;
    type operation = paths['/v1.0/hub/slot-swaps']['post'];
    type successResponse =
      operation['responses'][201]['content']['application/json'];

    const response = await this.$http.post<successResponse>(path, data, {
      headers: {
        'content-type': 'application/json',
      },
    });
    return response.data.data;
  }

  async connectConnectSlotSwap(
    slotSwapUuid: string,
    data: ConnectToSlotSwapPayload,
  ) {
    const path: keyof paths = `/v1.0/hub/slot-swaps/{ssi_uuid}/connections`;
    type operation = paths[typeof path]['post'];
    type successResponse =
      operation['responses'][201]['content']['application/json'];

    const pathWithUuid = path.replace('{ssi_uuid}', slotSwapUuid);

    const response = await this.$http.post<successResponse>(
      pathWithUuid,
      data,
      {
        headers: {
          'content-type': 'application/json',
        },
      },
    );
    const responseData = response.data.data;
    return responseData;
  }

  async deleteSlotSwap(uuid: string) {
    const path = `/v1.0/hub/slot-swaps/${uuid}` as keyof paths;
    type operation = paths['/v1.0/hub/slot-swaps/{ssi_uuid}']['delete'];
    type successResponse =
      operation['responses'][200]['content']['application/json'];

    const response = await this.$http.delete<successResponse>(path);
    return response?.data?.data;
  }

  async updateMyOrg(payload: components['schemas']['PatchOrgPayload']) {
    const path = '/v1.0/hub/my-org' as keyof paths;
    type operation = paths['/v1.0/hub/my-org']['patch'];
    type successResponse =
      operation['responses'][200]['content']['application/json'];

    const response = await this.$http.patch<successResponse>(path, payload);
    return response.data.data;
  }

  async updatePost(
    uuid: string,
    payload: components['schemas']['UpdateSlotSwapPayload'],
  ) {
    const path = `/v1.0/hub/slot-swaps/{ssi_uuid}` as keyof paths;
    type operation = paths['/v1.0/hub/slot-swaps/{ssi_uuid}']['patch'];
    type successResponse =
      operation['responses'][200]['content']['application/json'];

    const url = path.replace('{ssi_uuid}', uuid);

    const response = await this.$http.patch<successResponse>(url, payload);
    return response.data.data;
  }

  async updateConnectionStatus(
    postUuid: string,
    connectionUuid: string,
    payload: components['schemas']['UpdateSlotSwapConnectionStatusPayload'],
  ) {
    const path: keyof paths =
      '/v1.0/hub/slot-swaps/{ssi_uuid}/connection-status/{connection_uuid}';
    type operation =
      paths['/v1.0/hub/slot-swaps/{ssi_uuid}/connection-status/{connection_uuid}']['patch'];
    type successResponse =
      operation['responses'][200]['content']['application/json'];

    const url = path
      .replace('{ssi_uuid}', postUuid)
      .replace('{connection_uuid}', connectionUuid);

    const response = await this.$http.patch<successResponse>(url, payload);
    return response.data.data;
  }

  // my-org
  async loadMyOrg() {
    type operation = paths['/v1.0/hub/my-org']['get'];
    type successResponse =
      operation['responses'][200]['content']['application/json'];

    const response = await this.dedupeGet<successResponse>(`/v1.0/hub/my-org`);
    return response.data.data;
  }

  async updateMember(
    uuid: string,
    payload: components['schemas']['OrgAdminUpdateMemberPayload'],
  ) {
    const path: keyof paths = '/v1.0/hub/my-org/members/{account_uuid}';

    type operation = paths['/v1.0/hub/my-org/members/{account_uuid}']['patch'];
    type successResponse =
      operation['responses'][200]['content']['application/json'];

    const url = path.replace('{account_uuid}', uuid);

    const response = await this.$http.patch<successResponse>(url, payload);
    return response.data.data;
  }

  async updatePersonalSettings(
    payload: components['schemas']['OrgMemberUpdatePersonalSettingPayload'],
  ) {
    const path: keyof paths = '/v1.0/hub/my-org/personal-settings';

    type operation = paths['/v1.0/hub/my-org/personal-settings']['patch'];
    type successResponse =
      operation['responses'][200]['content']['application/json'];

    const response = await this.$http.patch<successResponse>(path, payload);
    return response.data.data;
  }
}
